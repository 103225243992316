export const units = [
  { name: "pound", abbreviation: "lb" },
  { name: "ounce", abbreviation: "oz" },
  { name: "gallon", abbreviation: "G" },
  { name: "quart", abbreviation: "q" },
  { name: "pint", abbreviation: "p" },
  { name: "cup", abbreviation: "C" },
  { name: "fluid_ounce", abbreviation: "fl. oz." },
  { name: "keg", abbreviation: "keg" },
  { name: "package", abbreviation: "pack" },
  { name: "each", abbreviation: "ea" },
  { name: "dozen", abbreviation: "doz" },
  { name: "bunch", abbreviation: "bn" },
  { name: "case", abbreviation: "cs" },
  { name: "bag", abbreviation: "bg" },
  { name: "tray", abbreviation: "tr" },
  { name: "sack", abbreviation: "sack" },
  { name: "box", abbreviation: "box" },
  { name: "wheel", abbreviation: "wheel" },
  { name: "pail", abbreviation: "pail" },
  { name: "piece", abbreviation: "piece" },
  { name: "tub", abbreviation: "tub" },
  { name: "foot", abbreviation: "foot" },
  { name: "loaf", abbreviation: "loaf" },
  { name: "can", abbreviation: "can" },
  { name: "count", abbreviation: "ct" },
  { name: "roll", abbreviation: "roll" },
  { name: "min", abbreviation: "min" },
  { name: "unit", abbreviation: "unit" },
  { name: "pair", abbreviation: "pair" },
  { name: "pallet", abbreviation: "pallet" },
  { name: "uomdes", abbreviation: "uomuid" },
  { name: "sodexo_bag", abbreviation: "sbg" },
  { name: "bushel", abbreviation: "bu" },
  { name: "flat", abbreviation: "fl" },
  { name: "bundle", abbreviation: "bun" },
  { name: "cas", abbreviation: "cas" },
  { name: "check", abbreviation: "chk" },
  { name: "ck", abbreviation: "ck" },
  { name: "cmb", abbreviation: "cmb" },
  { name: "cooler", abbreviation: "col" },
  { name: "crt", abbreviation: "crt" },
  { name: "fillet", abbreviation: "fil" },
  { name: "ft", abbreviation: "ft" },
  { name: "in", abbreviation: "in" },
  { name: "kilogram", abbreviation: "kg" },
  { name: "broken_pounds", abbreviation: "blb" },
  { name: "sodexo_pounds", abbreviation: "lbr" },
  { name: "lbx", abbreviation: "lbx" },
  { name: "lee", abbreviation: "lee" },
  { name: "minutes_labor", abbreviation: "minl" },
  { name: "minimum_sum_weight", abbreviation: "mnd" },
  { name: "minimum_selling_primary", abbreviation: "mnp" },
  { name: "minimum_sum_secondary", abbreviation: "mns" },
  { name: "ozb", abbreviation: "ozb" },
  { name: "ozs", abbreviation: "ozs" },
  { name: "pck", abbreviation: "pck" },
  { name: "quarter", abbreviation: "qtr" },
  { name: "jar", abbreviation: "jar" },
  { name: "rl", abbreviation: "rl" },
  { name: "shipment", abbreviation: "shp" },
  { name: "tin", abbreviation: "tin" },
  { name: "partial_primary_unit", abbreviation: "unt" },
  { name: "vat", abbreviation: "vat" },
  { name: "bottle", abbreviation: "btl" },
  { name: "bale", abbreviation: "bl" },
  { name: "drum", abbreviation: "drm" },
  { name: "tote", abbreviation: "tt" },
  { name: "sleeve", abbreviation: "slv" },
];
